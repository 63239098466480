import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

import ProjectsPageTemplate from '../../templates/projects-page'
import ProjectsSlidingPills from '../../components/ProjectsSlidingPills'

import Seo from '../../components/seo'
import Layout from '../../components/Layout'
import MatterComponent from '../../components/Matter'
import {
  FullWidthImage,
  HalfWidthImage,
  HalfWidthText,
  Testimonial,
  Mockup,
} from '../../components/projects'

import ReactSVG from '../../assets/logo/react-color-logo.svg'
import RailsSVG from '../../assets/logo/rails-color-logo.svg'
import ContentfulSVG from '../../assets/logo/contentful-logo.svg'

import { Headline3, Body1 } from '../../components/Typography'

import RowanSVG from '../../assets/logo/rowan-logo.svg'
import MeUndiesSVG from '../../assets/logo/meundies-logo.svg'

const teamImages = [
  '/images/jared.png',
  '/images/maddy.png',
  '/images/benjamin.png',
  '/images/kendra.png',
  '/images/sofia.png',
  // '/images/harmony.png',
  '/images/alistair.png',
]

const ReactIcon = props => {
  return (
    <ReactSVG
      {...props}
      style={{ display: 'block', height: '24px', width: '24px' }}
    />
  )
}

const Rails = props => {
  return (
    <RailsSVG
      {...props}
      style={{ display: 'block', height: '24px', width: '24px' }}
    />
  )
}

const ContentfulIcon = props => {
  return (
    <ContentfulSVG
      {...props}
      style={{ display: 'block', height: '24px', width: '24px' }}
    />
  )
}

const rowanPillsFirstRow = [
  ['6', 'Developers'],
  ['Infinite', 'Percent increase in online yarn sales'],
  ['370', 'Pull requests'],
  ['1992', 'Commits'],
  ['4000+', 'SKUs'],
  ['+156,427', 'Lines of code added'],
  ['1500+', 'Products'],
  ['-165,275', 'Lines of code deleted'],
]

const rowanPillsSecondRow = [
  'Headless CMS',
  'Kit Builder',
  'CMS Integration',
  'Store Locator',
  'Product Filtering',
  'ESP Integration',
]

const rowanPillsThirdRow = [
  [<Rails />, 'Ruby on Rails'],
  [<ReactIcon />, 'React'],
  [<ContentfulIcon />, 'Contentful'],
]

const RowanLogo = props => {
  const StyledLogo = styled(RowanSVG)`
    display: block;
    width: 189px;
    height: 36px;

    path {
      fill: var(--terminal-0);
    }
  `

  return <StyledLogo {...props} />
}

const MeUndiesLogo = props => {
  const StyledLogo = styled(MeUndiesSVG)`
    display: block;
    width: 189px;
    height: 36px;

    path {
      fill: var(--terminal-0);
    }
  `

  return <StyledLogo {...props} />
}

const OrangeLink = styled.a`
  ${Body1};
  color: var(--firewall-500);
  text-decoration: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
`

const MatterPlaceholder = styled.div`
  background: var(--terminal-500);
  height: 100vh;
  width: 100vw;
`

const Title = styled.h2`
  ${Headline3};
  color: var(--terminal-0);
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: center;
`

function Rowan() {
  const pageTitle = 'Rowan'
  const [windowResizing, setWindowResizing] = useState(false)

  useEffect(() => {
    let timeout
    const cachedWidth = window.innerWidth

    const handleResize = () => {
      clearTimeout(timeout)

      if (cachedWidth !== window.innerWidth) {
        setWindowResizing(true)
      }

      timeout = setTimeout(() => {
        setWindowResizing(false)
      }, 200)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const pageQuery = useStaticQuery(graphql`
    query {
      julien: file(relativePath: { eq: "julien.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rowanHeader: file(relativePath: { eq: "rowan-header.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      rowanFull: file(relativePath: { eq: "rowan-full.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rowanPortrait: file(relativePath: { eq: "rowan-portrait.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rowanMockup: file(relativePath: { eq: "rowan-mockup.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      meundiesHeader: file(relativePath: { eq: "meundies-header.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <ProjectsPageTemplate
      title={pageTitle}
      image={pageQuery.rowanHeader.childImageSharp.gatsbyImageData}
      logo={<RowanLogo />}
      nextimage={pageQuery.meundiesHeader.childImageSharp.gatsbyImageData}
      nextlogo={<MeUndiesLogo />}
      nextpath={'/work/meundies'}
      heading="Taking a successful wool and knitting brand from not selling yarn on the internet to selling yarn on the internet. And doing it well."
      copyrightYear={'2022'}
    >
      <Seo
        title={pageTitle}
        keywords={[
          `Ruby on Rails`,
          `Rails`,
          `React`,
          `Spree`,
          `Solidus`,
          `eCommerce`,
        ]}
      />
      <ProjectsSlidingPills
        firstrow={rowanPillsFirstRow}
        secondrow={rowanPillsSecondRow}
        thirdrow={rowanPillsThirdRow}
      />
      <OrangeLink href="https://knitrowan.com/" rel="nofollow">
        Visit Rowan →
      </OrangeLink>
      <Layout>
        <FullWidthImage
          image={pageQuery.rowanFull.childImageSharp.gatsbyImageData}
        />
        <HalfWidthText
          title="Overline"
          body="Founded in 1978 in West Yorkshire, England, Rowan is an established heritage wool and knitting brand, supplying quality products that can be translated into timeless design. Showcasing natural fibres in a paint-box colour palette, Rowan has grown into a worldwide company, servicing over 30 countries and counting."
        />
        <HalfWidthImage
          image={pageQuery.rowanPortrait.childImageSharp.gatsbyImageData}
        />
        <Testimonial
          image={pageQuery.julien.childImageSharp.gatsbyImageData}
          quotation="Super Good's expertise and patience has been critical in aligning the technology across our brands. Their team were able to leverage our existing infrastructure to quickly build and deploy a new Rowan experience that better fit the story and vision of the brand. We could not have done this project without them."
          name="Julien Blanc"
          title="Group eCommerce Director, DMC Group"
        />
        <Mockup image={pageQuery.rowanMockup.childImageSharp.gatsbyImageData} />
      </Layout>
      <Title>Contributors</Title>
      {windowResizing ? (
        <MatterPlaceholder />
      ) : (
        <MatterComponent images={teamImages} />
      )}
    </ProjectsPageTemplate>
  )
}

export default Rowan
